@import url(//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css);
@import url(//propeller.in/components/typography/css/typography.css);
@import url(//propeller.in/components/button/css/button.css);
@import url(//propeller.in/components/card/css/card.css);
@import url(//propeller.in/components/checkbox/css/checkbox.css);
/* Bootstrap css */

/* Propeller typography */

/* Propeller buttons */

/* Propeller card css */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #eeeeee;
}

h6 {
  font-size: 1.2em;
  font-weight: 600;
}

#preload-text {
  text-align: center;
  font-size: 2em;
}

nav .brand-logo {
  margin: 0 0 0 2vw;
}

.sources {
  padding: 2vh 5vw;
}

.nav {
  background-color: #212121;
  width: 100%;
  padding: 1vh 0;
  font-size: 2em;
  text-align: center;
}

.nav a{
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

label {
  color: #000;
  font-weight: 400;
}

.card-title {
  font-size: 1.2em;
  font-weight: 400;
}

.pmd-card {
  margin-bottom: 20px;
}

.sources {
  background-color: #FFF;
  height: 90vh;
}

.source-list {
  padding: 1vh 0 0 2vw;
}

.checkbox-orange[type="checkbox"].filled-in:checked + label:after{
  border: 2px solid #ff7043;
  background-color: #ff7043;
}

@media only screen and (min-width: 600px) {
  .news-list {
    width: 65w;
    margin: 0 17.5vw;
  }

  .sources {
    width: 65w;
    margin: 0 17.5vw;
  }
}
.App {
  /* text-align: center; */
}

@-webkit-keyframes rotate-infinite {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100%  {
    -webkit-transform: rotate(360deg); 
  }
}

.loading-spinner {
  -webkit-animation: rotate-infinite .8s linear infinite;
  border: 12px solid #055877;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
}


