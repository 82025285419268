.App {
  /* text-align: center; */
}

@-webkit-keyframes rotate-infinite {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100%  {
    -webkit-transform: rotate(360deg); 
  }
}

.loading-spinner {
  -webkit-animation: rotate-infinite .8s linear infinite;
  border: 12px solid #055877;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
}

