/* Bootstrap css */
@import "//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css";

/* Propeller typography */
@import "//propeller.in/components/typography/css/typography.css";

/* Propeller buttons */
@import "//propeller.in/components/button/css/button.css";

/* Propeller card css */
@import "//propeller.in/components/card/css/card.css";

@import "//propeller.in/components/checkbox/css/checkbox.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #eeeeee;
}

h6 {
  font-size: 1.2em;
  font-weight: 600;
}

#preload-text {
  text-align: center;
  font-size: 2em;
}

nav .brand-logo {
  margin: 0 0 0 2vw;
}

.sources {
  padding: 2vh 5vw;
}

.nav {
  background-color: #212121;
  width: 100%;
  padding: 1vh 0;
  font-size: 2em;
  text-align: center;
}

.nav a{
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

label {
  color: #000;
  font-weight: 400;
}

.card-title {
  font-size: 1.2em;
  font-weight: 400;
}

.pmd-card {
  margin-bottom: 20px;
}

.sources {
  background-color: #FFF;
  height: 90vh;
}

.source-list {
  padding: 1vh 0 0 2vw;
}

.checkbox-orange[type="checkbox"].filled-in:checked + label:after{
  border: 2px solid #ff7043;
  background-color: #ff7043;
}

@media only screen and (min-width: 600px) {
  .news-list {
    width: 65w;
    margin: 0 17.5vw;
  }

  .sources {
    width: 65w;
    margin: 0 17.5vw;
  }
}